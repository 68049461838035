








import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'
Component.registerHooks([
  'beforeRouteEnter',
])
@Component({
  name: 'iframe',
  components: {
  },
})
export default class iframe extends VueBase {
  private url: any = ''
  private origin = location.origin
  private map = {
    knowledge: '/dongtai_doc/',
    environment: '/dongtai_doc/docs/getting-started/start-shangye/',
    investigate: '/dongtai_doc/docs/getting-started/start-xjpc/'
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vm: any) => {
      console.log(" =============== ")
      console.log(vm)
      vm.url = vm.origin + vm.map[vm.$route.params.name]
    })
  }
  // created() {
  //   this.url = this.enable.includes(this.$route.params.url) ? 
  //     this.$route.params.url : ''
  // }
}
